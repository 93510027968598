import React from 'react';
import styled from 'styled-components';
import { Container, Title, SingleInput, SingleInputButton } from 'ui';
import { Stack } from '@tymate/margaret';
import axios from 'axios';
import qs from 'query-string';
import { Field, Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import icSendEmail from 'images/ic-send-email.svg';
import { GATSBY_ZAPIER_URL_NEWSLETTER } from '../constants';
import { ERRORS } from 'utils';
import Section from 'components/Section';

const Status = styled.div`
  color: #ffffff;
  ${({ theme }) => theme.fontStyles.bodySmall};
  margin-top: ${({ theme }) => theme.spacing(0.25)};
`;

const NewsletterSection = () => {
  const handleSubmitNewsletterForm = async (
    values,
    { setValues, resetForm, setStatus, setFieldError },
  ) => {
    try {
      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(values),
        url: GATSBY_ZAPIER_URL_NEWSLETTER,
      };

      await axios(options);
      setStatus('Votre inscription a bien été prise en compte');
      setValues({ email: '' });
      setTimeout(() => resetForm(), 5000);
    } catch (err) {
      setFieldError(
        'email',
        'Une erreur est survenue, merci de réessayer ultérieurement',
      );
      setTimeout(() => resetForm(), 5000);
    }
  };

  return (
    <Section
      variant="primaryDark"
      topDecorationColor="white"
      bottomDecorationColor="white"
    >
      <Container>
        <Stack direction="column" gutterSize={2} alignX="center">
          <Title variant="light">
            Inscrivez-vous à la Newsletter.
            <br />
            <strong>Recevez nos dernières actualités et articles.</strong>
          </Title>
          <Formik
            initialValues={{ email: '' }}
            onSubmit={handleSubmitNewsletterForm}
            validationSchema={Yup.object().shape({
              email: Yup.string().email(ERRORS.EMAIL).required(ERRORS.REQUIRED),
            })}
          >
            {({ isSubmitting, status }) => (
              <Form style={{ textAlign: 'left' }}>
                <div style={{ position: 'relative' }}>
                  <SingleInput
                    as={Field}
                    name="email"
                    placeholder="Votre adresse mail…"
                  />
                  <SingleInputButton disabled={isSubmitting}>
                    <img src={icSendEmail} alt="" />
                  </SingleInputButton>
                </div>
                <Status>
                  <ErrorMessage name="email" />
                  {status}
                </Status>
              </Form>
            )}
          </Formik>
        </Stack>
      </Container>
    </Section>
  );
};

export default NewsletterSection;
