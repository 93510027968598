import React from 'react';
import styled, { css } from 'styled-components';
import { Decoration } from 'ui';
import decorationTopPrimary from 'images/section-top-primary.svg';
import decorationBottomPrimary from 'images/section-bottom-primary.svg';
import decorationTopWhite from 'images/section-top-white.svg';
import decorationBottomWhite from 'images/section-bottom-white.svg';

export const SectionBase = styled.section`
  position: relative;
  padding-top: 4em;
  padding-bottom: 4em;

  ${({ variant }) =>
    variant === 'primaryDark' &&
    css`
      background-color: ${({ theme }) => theme.primaryDark};
    `}

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      background-color: ${({ theme }) => theme.primary};
    `}

  ${({ theme }) => theme.media.tablet`
    padding-top: 8em;
    padding-bottom: 8em;
  `}
`;

const Section = ({
  children,
  bottomDecorationColor,
  topDecorationColor,
  ...props
}) => (
  <SectionBase {...props}>
    {topDecorationColor === 'primary' && (
      <Decoration src={decorationTopPrimary} alt="" />
    )}
    {topDecorationColor === 'white' && (
      <Decoration src={decorationTopWhite} alt="" />
    )}
    {bottomDecorationColor === 'primary' && (
      <Decoration src={decorationBottomPrimary} variant="bottom" alt="" />
    )}
    {bottomDecorationColor === 'white' && (
      <Decoration src={decorationBottomWhite} variant="bottom" alt="" />
    )}
    {children}
  </SectionBase>
);

export default Section;
