import React from 'react';
import { graphql } from 'gatsby';
import { Layout, SEO, Hero } from 'components';
import { Container, MainTitle, Kicker } from 'ui';
import { Box, Stack } from '@tymate/margaret';
import styled from 'styled-components';
import {
  TwitterShareButton,
  LinkedinShareButton,
  FacebookShareButton,
} from 'react-share';
import { FaLinkedinIn, FaTwitter, FaFacebookF } from 'react-icons/fa';
import NewsletterSection from 'components/NewsletterSection';
import AvatarAndName from 'components/AvatarAndName';
import StructuredText from 'components/StructuredText';
import TableOfContent from 'components/TableOfContent';

const ShareButtons = styled(Stack).attrs(({ variant }) => ({
  gutterSize: variant === 'article' ? 2 : 0.5,
  marginBottom: 2,
}))`
  > button {
    outline: none;
  }

  ${({ variant, theme }) =>
    variant === 'article' &&
    `
      border: 1px solid currentColor;
      color: ${theme.primary};
      padding: ${theme.spacing(0.5)} ${theme.spacing(2)};
      border-radius: 999em;
    `}
`;

const ArticleMeta = styled(Stack).attrs({
  alignX: 'space-between',
  size: 'full',
  gutterSize: 0.5,
  paddingBottom: 1,
  marginBottom: 1,
})`
  border-bottom: 1px solid ${({ theme }) => theme.separatorSecondary};
`;

const ArticleBox = styled(Box).attrs({ marginBottom: 2 })`
  background-color: #ffffff;
  padding: ${({ theme }) => theme.spacing()};
  position: relative;
  z-index: 1;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.07), 0 4px 8px 0 rgba(0, 0, 0, 0.07);

  ${({ theme }) => theme.media.tablet`
    padding: ${({ theme }) => theme.spacing(2)};
  `}

  ${({ theme }) => theme.media.tablet`
    padding-left: ${({ theme }) => theme.spacing(4)};
    padding-right: ${({ theme }) => theme.spacing(4)};
  `}
`;

const Article = ({ data: { datoCmsArticle }, location }) => (
  <>
    <SEO
      title={datoCmsArticle?.metaTags?.title || datoCmsArticle.title}
      description={
        datoCmsArticle?.metaTags?.description || datoCmsArticle.kicker
      }
    />

    <Layout
      hero={
        <Hero cover={datoCmsArticle.cover} variant="bleeding">
          <MainTitle as="h1" variant="light">
            {datoCmsArticle.title}
          </MainTitle>
        </Hero>
      }
    >
      {datoCmsArticle.enableTableOfContents && (
        <TableOfContent data={datoCmsArticle} />
      )}

      <Container
        variant={datoCmsArticle.enableTableOfContents ? 'withAside' : 'narrow'}
      >
        <ArticleBox>
          <ArticleMeta>
            <AvatarAndName
              variant="inline"
              name={datoCmsArticle.author?.name}
              description={datoCmsArticle.author?.description}
              picture={datoCmsArticle.author?.picture}
              size="small"
            />
          </ArticleMeta>
          <Kicker>{datoCmsArticle.kicker}</Kicker>
          <StructuredText data={datoCmsArticle.content} />
        </ArticleBox>

        <ShareButtons>
          <span>Partagez cet article :</span>
          <TwitterShareButton
            title={`Philaw – ${datoCmsArticle.title}`}
            url={location.href}
          >
            <FaTwitter />
          </TwitterShareButton>
          <LinkedinShareButton
            title={`Philaw – ${datoCmsArticle.title}`}
            url={location.href}
          >
            <FaLinkedinIn />
          </LinkedinShareButton>
          <FacebookShareButton
            title={`Philaw – ${datoCmsArticle.title}`}
            url={location.href}
          >
            <FaFacebookF />
          </FacebookShareButton>
        </ShareButtons>
      </Container>
      <NewsletterSection />
    </Layout>
  </>
);

export const query = graphql`
  query($slug: String!) {
    datoCmsArticle(slug: { eq: $slug }) {
      id
      slug
      title
      enableTableOfContents
      tags {
        slug
        name
      }
      metaTags {
        title
        description
      }
      kicker
      content {
        value
        links {
          ... on DatoCmsArticle {
            id: originalId
            __typename
            slug
            title
            kicker
            cover {
              gatsbyImageData(aspectRatio: 1, layout: FIXED, width: 128)
            }
            author {
              id
              name
              description
              picture {
                gatsbyImageData(aspectRatio: 1, width: 40)
              }
            }
          }
        }
        blocks {
          ... on DatoCmsPicture {
            id: originalId
            __typename
            name
            picture {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
          ... on DatoCmsVideo {
            id: originalId
            __typename
            videoLink {
              url
              title
              provider
              providerUid
              thumbnailUrl
              width
              height
            }
          }
        }
      }
      cover {
        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.77)
      }
      author {
        id
        name
        description
        picture {
          gatsbyImageData(aspectRatio: 1, width: 40)
        }
      }
    }
  }
`;

export default Article;
